import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDriversList() {
  // Use toast
  const toast = useToast()

  const refDriverListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'customerName', label:'Customer', sortable: false },
    { key: 'name', sortable: false },
    { key: 'document', sortable: true },
    { key: 'licenseType', label:'License Type', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalDrivers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  

  const dataMeta = computed(() => {
    const localItemsCount = refDriverListTable.value ? refDriverListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDrivers.value,
    }
  })

  const refetchData = () => {
    refDriverListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchDrivers = (ctx, callback) => {
    store
      .dispatch('app-driver/fetchDrivers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        
      })
      .then(response => {
        const drivers = response.data.data;
        const total = response.data.length;
        callback(drivers)
        totalDrivers.value = response.data.totalItems
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching drivers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  

  return {
    fetchDrivers,
    tableColumns,
    perPage,
    currentPage,
    totalDrivers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDriverListTable,

    
    refetchData,

   
  }
}

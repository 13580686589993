import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDrivers(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/Driver/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDriver(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`/api/v1/driver?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDriver(ctx, driverData) {
            return new Promise((resolve, reject) => {

                let driver = driverData.driverData
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/driver', driver)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateDriver(ctx, driverData) {
            return new Promise((resolve, reject) => {

                let driver = driverData.driverData
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .put('/api/v1/driver', driver)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteDriver(ctx, { id }) {
            return new Promise((resolve, reject) => {
                console.log('ID STORE', id)
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/driver?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCustomers(ctx, queryParams) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Customer/search?Search=${queryParams.q}&Page=1&PerPage=5`)
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCustomer(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/Customer?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}